import React, { useMemo, useRef, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  IconButton,
  VStack,
  Text,
  Button,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusAnalystTeam'
import { ITeamMembersDTO } from '@/DTOs/teams/teams.DTO'

import { analystsFilterExample } from '../../exampleData'
import AddAnalystModal from '../AddAnalystModal/AddAnalystsModal'
import { mainAnalystTableHeaders } from './exampleData'

const TeamAnalystTable = ({
  analysts,
  isAnalystsLoading,
}: {
  analysts: Array<ITeamMembersDTO>
  isAnalystsLoading: boolean
}) => {
  const [pageIndex, setPageIndex] = useState(0)
  const {
    isOpen: isAddAnalystOpen,
    onOpen: onAddAnalystOpen,
    onClose: onAddAnalystClose,
  } = useDisclosure()
  const navigate = useNavigate()

  const pageSizeOptions = [10, 20, 30, 40, 50]
  const itemsPerPage = 5
  const totalAnalysts = analysts.length
  const totalPages = Math.ceil(totalAnalysts / itemsPerPage)

  const handleOnChangePageInfo = ({ pageIndex }: IPageInfoProps) => {
    setPageIndex(pageIndex)
  }
  const handleAddAnalyst = () => {
    onAddAnalystOpen()
  }

  const analystsDataTable = useMemo(() => {
    const analystData = analysts ? analysts : []
    const mappedAnalysts = analystData.map(analyst => {
      const analystInfo = (name: string, cpf: string, email: string) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {name}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {cpf} | {email}
            </Text>
          </Box>
        </VStack>
      )

      const editButton = (id: string) => {
        const handleEdit = () => {
          // TODO: navigate(`/configuracoes/equipes/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            height="unset"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      return {
        user: analystInfo(
          analyst.coUser.noUserName,
          'analyst.cpf',
          analyst.coUser.dsInstitutionalEmail
        ),
        date: analyst.dtCreatedAt,
        status: StatusTag({ tag: analyst.stStatus ? 'active' : 'inactive' }),
        edit: editButton(analyst.coUser.coUser as string),
        status_code: analyst.stStatus,
      }
    })
    return mappedAnalysts
  }, [analysts])

  return (
    <Stack gap={4}>
      <DataFilter
        testId="teams-filter"
        canPrint={false}
        canDownload={false}
        onlySearch={true}
        filters={analystsFilterExample}
        onChangeFilter={() => {}}
      />
      <DataTable
        rowId="cod_team_analyst"
        headers={mainAnalystTableHeaders}
        data={analystsDataTable}
        isLoading={isAnalystsLoading}
      />
      <Button
        leftIcon={<FaPlusCircle size="14px" color="white" />}
        size="sm"
        backgroundColor="brand.primary.dark_1"
        color="white"
        _hover={{ bg: 'brand.primary.dark_2' }}
        width="100%"
        onClick={handleAddAnalyst}
      >
        Adicionar Analista
      </Button>
      <Pagination
        pageIndex={pageIndex}
        optionsItemsPerPage={pageSizeOptions}
        itemsPerPage={itemsPerPage}
        pageCount={totalPages}
        itemsCount={totalAnalysts}
        onChangePageInfo={handleOnChangePageInfo}
      />
      <AddAnalystModal isOpen={isAddAnalystOpen} onClose={onAddAnalystClose} />
    </Stack>
  )
}
export default TeamAnalystTable
