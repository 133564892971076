import React, { useMemo } from 'react'
import { FaPlusCircle } from 'react-icons/fa'

import { on } from 'events'
import { Form, Formik } from 'formik'

import { Button, Flex, Stack, Text, useToast } from '@chakra-ui/react'

import { useQueryUserProfiles } from '@/api/teams/queries'
import { useQueryUsers } from '@/api/user/management/queries'
import { Select } from '@/components/Forms'
import { IOption } from '@/components/Forms/Select/types'
import Modal from '@/components/Modal'

import { IAddAnalystModalProps } from './types'

const AddAnalystModal = ({
  isOpen,
  onClose,
}: IAddAnalystModalProps): JSX.Element => {
  const toast = useToast()
  const { data: profiles } = useQueryUserProfiles()
  const analystProfile = useMemo(() => {
    return profiles?.find(profile => profile.name === 'Analista')
  }, [profiles])

  const { data: users, isLoading: isLoadingUsers } = useQueryUsers()

  const analystsOptions: IOption[] = useMemo(() => {
    return users
      ? users
          ?.filter(user => user.ncoProfile[0] === analystProfile?.id)
          .reduce((acc, user) => {
            acc.push({ value: user.coUser[0], label: user.noUserName })
            return acc
          }, [] as IOption[])
      : []
  }, [users, analystProfile])
  const handleAddAnalyst = () => {
    onClose
  }

  return (
    <Modal
      title="Adicionar analista a equipe"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Stack gap={8}>
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
          }}
          onSubmit={handleAddAnalyst}
        >
          <Form>
            <Select
              name="name"
              label="Escolha do analista disponível"
              placeholder="Selecione um analista"
              isLoading={isLoadingUsers}
              options={analystsOptions}
            />
          </Form>
        </Formik>
        <Flex gap={4} direction={'column'} justifyContent={'flex-end'}>
          <Button
            size="md"
            borderRadius="full"
            leftIcon={<FaPlusCircle size="14px" color="white" />}
            backgroundColor="brand.primary.dark_1"
            color="white"
            onClick={() => {}}
          >
            Sim, quero adicionar
          </Button>
          <Button
            size="md"
            variant={'outline'}
            borderRadius="full"
            onClick={onClose}
          >
            Voltar
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}

export default AddAnalystModal
