import React from 'react'
import { useMemo, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

import { FormikValues } from 'formik'

import { useToast, IconButton } from '@chakra-ui/react'

import {
  useQueryGetCities,
  useQueryGetCountries,
  useQueryGetStates,
} from '@/api/address/queries'
import {
  useQueryGetEducationLevels,
  useQueryGetSpecialNeeds,
} from '@/api/user/auxiliaries/queries'
import {
  useMutationRenewalUsers,
  useMutationUnlinkUsers,
  useMutationUpdateMyAccount,
} from '@/api/user/management/mutations'
import { useQueryUserTeams } from '@/api/user/management/queries'
import { useQueryUser } from '@/api/user/management/queries'
import { TUpdateMyAccountParams } from '@/api/user/management/types'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'
import { getErrorDetails } from '@/utils/error'

import { IUserStatusMapping } from '../types'

const editButton = (id: number) => {
  return (
    <IconButton
      aria-label="Editar"
      variant="ghost"
      size="sm"
      height="unset"
      icon={<FaEdit />}
      color="brand.primary.dark_1"
    />
  )
}

export const useUserDetailsController = () => {
  const { userId } = useParams()
  const [activeTabId, setActiveTabId] = useState('informacoes-pessoais')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })
  const toast = useToast()

  const { data: userData, isLoading: isLoadingUserData } = useQueryUser(
    { coUser: userId as string },
    {
      enabled: !!userId,
      onError: () => {
        toast({
          title: 'Erro ao buscar dados do usuário',
          description: 'Não foi possível obter os dados do usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const { data: userTeams, isLoading: isLoadingUserTeams } = useQueryUserTeams(
    {
      coUser: Number(userId),
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    {
      enabled: !!userId,
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage,
          pageSize: data.pagination.pageSize,
          itemsCount: data.pagination.count,
          pageCount: data.pagination.totalPages,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao buscar os times do usuário',
          description: 'Não foi possível obter os times do usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const { data: specialNeeds } = useQueryGetSpecialNeeds()

  const { data: educationLevel } = useQueryGetEducationLevels()

  const { data: rawCountries = [], isLoading: isLoadingCountries } =
    useQueryGetCountries({
      onError: () => {
        toast({
          title: 'Erro ao buscar países',
          description: 'Ocorreu um erro ao buscar os países.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    })

  const { data: rawStates = [], isLoading: isLoadingStates } =
    useQueryGetStates(
      {
        country: selectedCountry,
      },
      {
        enabled: !!selectedCountry,
        onError: () => {
          toast({
            title: 'Erro ao buscar estados',
            description: 'Ocorreu um erro ao buscar os estados.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },
      }
    )

  const { data: rawCities = [], isLoading: isLoadingCities } =
    useQueryGetCities(
      {
        uf: selectedState,
        country: selectedCountry,
      },
      {
        enabled: !!selectedState && !!selectedCountry,
        onError: () => {
          toast({
            title: 'Erro ao buscar cidades',
            description: 'Ocorreu um erro ao buscar as cidades.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },
      }
    )

  const { mutate: updateMyAccount, isLoading: isUpdatingAccount } =
    useMutationUpdateMyAccount({
      onSuccess: () => {
        toast({
          title: 'Dados atualizados',
          description: 'Seus dados foram atualizados com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao atualizar dados',
          description: 'Ocorreu um erro ao atualizar seus dados.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })

  const { mutate: renewalUsers, isLoading: isRenewalLoading } =
    useMutationRenewalUsers({
      onSuccess: () => {
        toast({
          title: 'Vínculo renovado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: error => {
        toast({
          title: 'Erro ao renovar vínculo',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const { mutate: unlinkUsers, isLoading: isUnlinkLoading } =
    useMutationUnlinkUsers({
      onSuccess: () => {
        toast({
          title: 'Vínculo encerrado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: error => {
        toast({
          title: 'Erro ao encerrar vínculo',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const specialNeedsOptions = useMemo(() => {
    return specialNeeds?.map(need => ({
      value: need.coSpecialNeed,
      label: need.dsSpecialNeed,
    }))
  }, [specialNeeds])

  const educationLevelsOptions = useMemo(() => {
    return educationLevel?.map(level => ({
      value: level.coEducationLevel,
      label: level.dsEducationLevel,
    }))
  }, [educationLevel])

  const countries = useMemo(() => {
    return Array.from(new Set(rawCountries)).map(country => {
      return {
        value: country,
        label: country,
      }
    })
  }, [rawCountries])

  const states = useMemo(() => {
    return Array.from(new Set(rawStates)).map(state => {
      return {
        value: state,
        label: state,
      }
    })
  }, [rawStates])

  const cities = useMemo(() => {
    return Array.from(new Set(rawCities)).map(city => {
      return {
        value: city,
        label: city,
      }
    })
  }, [rawCities])

  const userValues = useMemo(() => {
    setSelectedCountry(userData?.dsCountry || '')
    setSelectedState(userData?.dsUfProvince || '')
    setSelectedCity(userData?.dsCity || '')

    return {
      coUser: userData?.coUser || '',
      noUserName: userData?.noUserName || '',
      noUserSocialName: userData?.noUserSocialName || '',
      dsCpf: userData?.dsCpf || '',
      dtBirthDate: userData?.dtBirthDate || '',
      coSpecialNeed: userData?.coSpecialNeed || '',
      coEducationLevel: userData?.coEducationLevel || '',
      dsInstitution: userData?.dsInstitution || '',
      dsInstitutionalEmail: userData?.dsInstitutionalEmail || '',
      dsRolePositionJob: userData?.dsRolePositionJob || '',
      dsGovbrEmail: userData?.dsGovbrEmail || '',
      dsCellphone: userData?.dsCellphone || '',
      dsCountry: userData?.dsCountry || '',
      dsUfProvince: userData?.dsUfProvince || '',
      dsCity: userData?.dsCity || '',
      dtJobBindStart: userData?.dtJobBindStart || '',
      dtRenewal: userData?.dtRenewal?.split('T')[0] || '',
      dsPhotoUrl: userData?.dsPhotoUrl || '',
    }
  }, [userData])

  const userTeamsTableData = useMemo(() => {
    const userTeamsMapped = userTeams?.results.map(team => {
      const status = team.stStatus ? 'active' : 'finished'
      const teamRole = team.members.find(
        member => member.coUser.coUser === userData?.coUser
      )?.coUserRole
      return {
        id: team.coTeam,
        team: team.noTeam,
        object: team.object.dsObject,
        phase: team.stageObject.stStatus,
        permission: teamRole,
        status: <Tag group="statusUserTeam" value={status} />,
      }
    })

    return userTeamsMapped || []
  }, [userTeams])

  const isAdmin = userData?.ncoProfile[0] === 1
  const statusTagValue = IUserStatusMapping[userData?.dsStatus as string]

  const handleSubmit = (values: FormikValues) => {
    updateMyAccount(values as TUpdateMyAccountParams)
  }

  const handleOnChangePageInfoUserTeams = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  return {
    userId,
    activeTabId,
    setActiveTabId,
    userValues,
    userData,
    isLoadingUserData,
    isAdmin,
    statusTagValue,
    specialNeedsOptions,
    educationLevelsOptions,
    countries,
    states,
    cities,
    isLoadingCountries,
    isLoadingStates: isLoadingStates && Boolean(selectedCountry),
    isLoadingCities: isLoadingCities && Boolean(selectedState),
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    updateMyAccount,
    isUpdatingAccount,
    handleSubmit,
    renewalUsers,
    isRenewalLoading,
    unlinkUsers,
    isUnlinkLoading,
    userTeamsTableData,
    isLoadingUserTeams,
    pagination,
    handleOnChangePageInfoUserTeams,
  }
}
