import React from 'react'
import { useState } from 'react'
import { FaFile } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, Stack, Text } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'

import { tabs } from './controller'

const FailList = () => {
  const navigate = useNavigate()

  const [activeTabId, setActiveTabId] = useState('ativos')

  const handleRegisterGroupFails = () => {
    navigate('/formularios/cadastrar-grupo-de-falhas')
  }

  const initialPath = location.pathname.split('/').pop()

  const tabs: Array<ITabItem> = [
    {
      id: 'formulario',
      label: 'Formulário',
    },
    // {
    //   id: 'diligencias',
    //   label: 'Diligências',
    // },
    {
      id: 'perguntas',
      label: 'Perguntas',
    },
    {
      id: 'falhas',
      label: 'Falhas',
    },
  ]

  const handleTabClick = (id: string) => {
    navigate(`/formularios/${id}`)
  }

  return (
    <>
      <Tabs
        items={tabs}
        initialTab={initialPath}
        onTabClick={({ id }: ITabInfo) => handleTabClick(id)}
      />
      <Stack p={4} boxShadow="md" borderRadius="md">
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
            Lista de falhas
          </Text>
          <Button
            leftIcon={<FaFile />}
            borderRadius="md"
            size="sm"
            onClick={() => handleRegisterGroupFails()}
          >
            Cadastrar grupo de falhas
          </Button>
        </Flex>
        <Tabs
          items={tabs}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
          variant="rounded"
        />
      </Stack>
    </>
  )
}

export default FailList
