import React from 'react'

import { Flex } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

const Teams = () => {
  const {
    userTeamsTableData,
    isLoadingUserTeams,
    pagination,
    handleOnChangePageInfoUserTeams: handleOnChangePageInfo,
  } = useProfileContext()

  const header: Array<IHeader> = [
    {
      name: 'EQUIPE',
      key: 'team',
    },
    {
      name: 'OBJETO',
      key: 'object',
    },
    {
      name: 'FASE',
      key: 'phase',
    },
    {
      name: 'PERMISSÃO',
      key: 'permission',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '10%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  return (
    <Flex direction="column" gap={4}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
      >
        <DataFilter
          testId="data-filter"
          filters={[]}
          onlySearch
          canDownload={false}
          canPrint={false}
          onChangeFilter={() => {}}
        />
      </Flex>
      <DataTable
        headers={header}
        data={userTeamsTableData}
        isLoading={isLoadingUserTeams}
      />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Flex>
  )
}

export default Teams
